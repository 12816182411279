<template>
    <div class="component-box">
        <el-tabs v-model="main_tab" class="el-tabs-custom">
            <!-- <el-tab-pane name="first">
                <span slot="label">
                    {{ $t('common.credit_card') }}
                </span>
                <CreditCard></CreditCard>
            </el-tab-pane> -->
            <el-tab-pane name="second">
                <span slot="label">
                    {{ $t('common.billing_address') }}
                </span>
                <BillingAddress></BillingAddress>
            </el-tab-pane>
            <!-- <el-tab-pane name="thirst">
                <span slot="label">
                    {{ $t('common.invoice_info') }}
                </span>

                <InvoiceInfo></InvoiceInfo>
            </el-tab-pane> -->
        </el-tabs>
    </div>
</template>

<script>
import CreditCard from './credit-card'
import BillingAddress from './billing-address'
import InvoiceInfo from './invoice-info'

export default {
    components: {
        // CreditCard,
        BillingAddress
        // InvoiceInfo
    },

    data() {
        return {
            main_tab: 'second'
        }
    }
}
</script>
