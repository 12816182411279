<template>
    <div>
        <div class="flex items-center">
            <p class="font-semibold">
                {{ $t('common.credit_card') }}
            </p>

            <el-button
                icon="el-icon-plus"
                class="ml-auto"
                circle
                type="primary"
                @click="show_dialog = true"
            ></el-button>
        </div>

        <div v-if="credit_card_list.length" class="grid grid-cols-2 gap-4 mt-6">
            <div
                v-for="item in credit_card_list"
                :key="item._id"
                class="border-border border-[1px] rounded-lg p-4"
            >
                <div class="flex items-center">
                    <p>
                        {{ item.number }}
                    </p>

                    <el-button
                        icon="el-icon-edit-outline"
                        class="ml-auto"
                        circle
                        @click="handleUpdateCreditCard(item)"
                    ></el-button>

                    <el-button
                        icon="el-icon-delete"
                        class="ml-auto"
                        circle
                        type="danger"
                        @click="handleDeleteCreditCard(item._id)"
                    ></el-button>
                </div>

                <div class="flex text-sm">
                    <p>{{ item.first_name }} {{ item.last_name }}</p>
                    <p class="ml-auto mt-1">
                        {{ item.expire_month }}/{{ item.expire_year }}
                    </p>
                </div>
            </div>
        </div>
        <NoData v-else class="h-[240px]"></NoData>

        <AddCreditCardDialog :visible.sync="show_dialog"></AddCreditCardDialog>
    </div>
</template>

<script>
import { getCreditCards, deleteCreditCard } from '@/services/atosa/payment'
import AddCreditCardDialog from './add-credit-card-dialog'

export default {
    components: {
        AddCreditCardDialog
    },

    data() {
        return {
            show_dialog: false,
            credit_card_list: []
        }
    },

    mounted() {
        this.getCreditCards()
    },

    methods: {
        async getCreditCards() {
            try {
                const response = await getCreditCards()
                if (response.error === 0) {
                    this.credit_card_list = response.data
                }
            } catch (error) {
                console.error(error)
            }
        },

        async handleDeleteCreditCard(id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                const response = await deleteCreditCard(id)

                if (response.error === 0) {
                    this.p__showNotify(
                        'success',
                        'Xóa thông tin thẻ thành công'
                    )

                    this.getCreditCards()
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
