<template>
    <el-dialog
        :visible.sync="visible_in_line"
        width="440px"
        class="el-dialog-center"
        :close-on-click-modal="true"
        :append-to-body="true"
        :show-close="false"
    >
        <div slot="title">
            <p class="text-lg font-semibold">
                {{ $t('common.credit_card') }}
            </p>
        </div>

        <div class="flex flex-col space-y-4">
            <div class="flex gap-4">
                <label-outside-input-select
                    :label="$t('common.first_name')"
                    class="w-full"
                >
                    <el-input
                        v-model="info_credit_card.first_name"
                        :clearable="true"
                        size="small"
                    >
                    </el-input>
                </label-outside-input-select>
                <label-outside-input-select
                    :label="$t('common.last_name')"
                    class="w-full"
                >
                    <el-input
                        v-model="info_credit_card.last_name"
                        :clearable="true"
                        size="small"
                    >
                    </el-input>
                </label-outside-input-select>
            </div>

            <label-outside-input-select
                :label="$t('common.number')"
                class="w-full"
            >
                <el-input
                    v-model="info_credit_card.number"
                    :clearable="true"
                    size="small"
                >
                </el-input>
            </label-outside-input-select>

            <div class="flex gap-4">
                <label-outside-input-select
                    :label="$t('common.expire_month')"
                    class="w-full"
                >
                    <el-input
                        v-model="info_credit_card.expire_month"
                        :clearable="true"
                        size="small"
                    >
                    </el-input>
                </label-outside-input-select>
                <label-outside-input-select
                    :label="$t('common.expire_year')"
                    class="w-full"
                >
                    <el-input
                        v-model="info_credit_card.expire_year"
                        :clearable="true"
                        size="small"
                    >
                    </el-input>
                </label-outside-input-select>
                <label-outside-input-select label="CCV" class="w-full">
                    <el-input
                        v-model="info_credit_card.ccv2"
                        :clearable="true"
                        size="small"
                    >
                    </el-input>
                </label-outside-input-select>
            </div>

            <el-button
                v-if="selected_update_item"
                slot="append"
                type="primary"
                @click="handleUpdateBillingAddress"
            >
                {{ $t('button.edit') }}
            </el-button>

            <el-button
                v-else
                slot="append"
                type="primary"
                @click="handleCreate"
            >
                {{ $t('button.create') }}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import { createCreditCard } from '@/services/atosa/payment'

const template_info_credit_card = {
    type: '',
    number: '',
    expire_month: '',
    expire_year: '',
    cvv2: '',
    first_name: '',
    last_name: ''
}

export default {
    props: ['visible'],

    data() {
        return {
            info_credit_card: template_info_credit_card
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    methods: {
        async handleCreate() {
            try {
                const response = await createCreditCard(this.info_credit_card)
                if (response.error === 0) {
                    this.p__showNotify('success', 'Thêm thẻ thành công')
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
