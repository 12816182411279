<template>
    <div>
        <div class="flex items-center">
            <p class="font-semibold">
                {{ $t('common.billing_address') }}
            </p>

            <el-button
                icon="el-icon-plus"
                class="ml-auto"
                circle
                type="primary"
                @click="show_set_billing_dialog = true"
            ></el-button>
        </div>

        <el-alert
            :title="$t('page.billing.desc_billing_address')"
            type="success"
            class="my-2"
            :closable="false"
        >
        </el-alert>

        <div v-if="billing_list.length" class="grid grid-cols-2 gap-4 mt-6">
            <div
                v-for="item in billing_list"
                :key="item._id"
                class="border-border border-[1px] rounded-lg p-4"
            >
                <div class="flex items-center">
                    <p>
                        {{ item.name }}
                    </p>

                    <el-button
                        icon="el-icon-edit-outline"
                        class="ml-auto"
                        circle
                        @click="handleOpenUpdateBilling(item)"
                    ></el-button>

                    <el-button
                        icon="el-icon-delete"
                        class="ml-auto"
                        circle
                        type="danger"
                        @click="handleDeleteBilling(item._id)"
                    ></el-button>
                </div>

                <p class="text-sm">
                    <i class="el-icon-phone-outline"></i>

                    {{ item.phone }}
                </p>

                <p class="text-sm">
                    <i class="el-icon-message"></i>

                    {{ item.email }}
                </p>

                <p class="text-sm">
                    <i class="el-icon-place"></i>

                    {{ item.address_line_1 }} - {{ item.admin_area_1 }} -
                    {{ item.country_code }}
                </p>

                <!-- <p class="text-sm">
                    <i class="el-icon-place"></i>

                    {{ item.address_line_2 }} - {{ item.admin_area_2 }}
                </p> -->

                <p class="text-sm">
                    <i class="el-icon-location-information"></i>

                    {{ item.postal_code }}
                </p>
            </div>
        </div>
        <NoData v-else class="h-[240px]"></NoData>

        <el-dialog
            :visible.sync="show_set_billing_dialog"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="480px"
        >
            <div slot="title">
                <p class="text-lg font-semibold">
                    {{ $t('common.billing_address') }}
                </p>
            </div>

            <div class="flex flex-col space-y-4">
                <div class="flex gap-4">
                    <label-outside-input-select
                        :label="$t('common.name')"
                        class="w-full"
                    >
                        <el-input
                            v-model="info_billing_address_to_set.name"
                            :clearable="true"
                            size="small"
                        >
                        </el-input>
                    </label-outside-input-select>
                </div>

                <div class="flex gap-4">
                    <label-outside-input-select
                        :label="$t('common.email_billing')"
                        class="w-full"
                    >
                        <el-input
                            v-model="info_billing_address_to_set.email"
                            :clearable="true"
                            size="small"
                        >
                        </el-input>
                    </label-outside-input-select>
                    <label-outside-input-select
                        :label="$t('common.phone_number')"
                        class="w-full"
                    >
                        <el-input
                            v-model="info_billing_address_to_set.phone"
                            :clearable="true"
                            size="small"
                        >
                        </el-input>
                    </label-outside-input-select>
                </div>

                <div class="flex gap-4">
                    <label-outside-input-select
                        :label="$t('common.address')"
                        class="w-full"
                    >
                        <el-input
                            v-model="info_billing_address_to_set.address_line_1"
                            :clearable="true"
                            size="small"
                        >
                        </el-input>
                    </label-outside-input-select>
                    <label-outside-input-select
                        :label="$t('common.city')"
                        class="w-full"
                    >
                        <el-input
                            v-model="info_billing_address_to_set.admin_area_1"
                            :clearable="true"
                            size="small"
                        >
                        </el-input>
                    </label-outside-input-select>
                </div>

                <div class="flex gap-4">
                    <label-outside-input-select
                        :label="$t('common.country')"
                        class="w-full"
                    >
                        <el-select
                            v-model="info_billing_address_to_set.country_code"
                            size="small"
                            :filterable="true"
                            :placeholder="$t('input.placeholder.please_select')"
                            class="w-full"
                        >
                            <el-option
                                v-for="item in country_options"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                            >
                            </el-option>
                        </el-select>
                    </label-outside-input-select>

                    <label-outside-input-select
                        :label="$t('common.postal_code')"
                        class="w-full"
                    >
                        <el-input
                            v-model="info_billing_address_to_set.postal_code"
                            :clearable="true"
                            size="small"
                        >
                        </el-input>
                    </label-outside-input-select>
                </div>

                <el-button
                    v-if="selected_update_item"
                    slot="append"
                    type="primary"
                    @click="handleUpdateBillingAddress"
                >
                    {{ $t('button.edit') }}
                </el-button>

                <el-button
                    v-else
                    slot="append"
                    type="primary"
                    @click="handleCreateBillingAddress"
                >
                    {{ $t('button.create') }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getBillingAddress,
    updateBillingAddress,
    deleteBillingAddress
} from '@/services/atosa/payment'
import { country_list } from './country-list'

const template_info_billing_address = {
    name: '',
    email: '',
    phone: '',
    address_line_1: '',
    address_line_2: '',
    admin_area_1: '',
    admin_area_2: '',
    postal_code: '',
    country_code: '',
    activate: true
}

export default {
    data() {
        return {
            show_set_billing_dialog: false,
            info_billing_address_to_set: template_info_billing_address,
            billing_list: [],
            country_options: country_list,
            selected_update_item: null
        }
    },

    watch: {
        show_set_billing_dialog() {
            if (!this.show_set_billing_dialog) {
                this.selected_update_item = null
                this.info_billing_address_to_set = template_info_billing_address
            }
        }
    },

    mounted() {
        this.getBillingAddress()
    },

    methods: {
        async getBillingAddress() {
            try {
                const response = await getBillingAddress()
                if (response.error === 0) {
                    this.billing_list = response.data
                }
            } catch (error) {
                console.error(error)
            }
        },

        async handleUpdateBillingAddress() {
            try {
                const response = await updateBillingAddress(
                    this.info_billing_address_to_set
                )

                if (response.error === 0) {
                    this.p__showNotify('success', 'Sửa địa chỉ thành công')

                    this.getBillingAddress()

                    this.show_set_billing_dialog = false
                }
            } catch (error) {
                console.error(error)
            }
        },

        async handleCreateBillingAddress() {
            try {
                const response = await updateBillingAddress(
                    this.info_billing_address_to_set
                )

                if (response.error === 0) {
                    this.p__showNotify('success', 'Tạo địa chỉ thành công')

                    this.getBillingAddress()

                    this.show_set_billing_dialog = false
                }
            } catch (error) {
                console.error(error)
            }
        },

        handleOpenUpdateBilling(item) {
            this.show_set_billing_dialog = true
            this.selected_update_item = item
            this.info_billing_address_to_set = { ...item }
        },

        async handleDeleteBilling(id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                const response = await deleteBillingAddress(id)

                if (response.error === 0) {
                    this.p__showNotify('success', 'Xóa địa chỉ thành công')

                    this.getBillingAddress()
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
