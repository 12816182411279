import request from './_config'

export function getBillingAddress() {
    return request({
        url: 'v1/payment/billing-address',
        method: 'get'
    })
}

export function updateBillingAddress(data) {
    return request({
        url: 'v1/payment/billing-address',
        method: 'post',
        data
    })
}

export function deleteBillingAddress(id) {
    return request({
        url: `v1/payment/billing-address/${id}`,
        method: 'delete'
    })
}

export function createCreditCard(data) {
    return request({
        url: 'v1/payment/credit-card',
        method: 'post',
        data
    })
}

export function getCreditCards() {
    return request({
        url: 'v1/payment/credit-card',
        method: 'get'
    })
}

export function deleteCreditCard(id) {
    return request({
        url: `v1/payment/credit-card/${id}`,
        method: 'delete'
    })
}

export function getPaymentAuto(channel) {
    return request({
        url: `v1/store/payment/auto?channel=${channel}`,
        method: 'get'
    })
}

export function updatePaymentAuto(channel, data) {
    return request({
        url: `v1/store/payment/auto?channel=${channel}`,
        method: 'post',
        data
    })
}
